import React from "react"
import LoginBody from "./Login_Body"

const Login = function() {
    return (
        <div> 
            <LoginBody />
        </div>
    )
}

export default Login;