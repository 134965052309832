import React from "react";

const Loader = () => {
  return (
    <div style={{ display: "flex" }} id="main-alert-box">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
