import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    diagHeadings : []
}

const diagSlice = createSlice({
    name: "diag",
    initialState,
    reducers : {
        setDiag: (state, action) => {
            state.diagHeadings = action.payload
        }
    }
})

export const { setDiag} = diagSlice.actions;
export default diagSlice.reducer;