import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import AverageSpeed from "../../assets/AverageSpeed.png";
import arrow from "../../assets/arrow.png";
import DistanceTravelled from "../../assets/DistanceTravelled.png";
import ErrorCount from "../../assets/ErrorCount.png";
import PeakCurrent from "../../assets/peak_current.png";
import RecievedRecords from "../../assets/recieved_records.png";
import ActualRecords from "../../assets/actual_records.png";
import "./vehicleDetail.css";

let errorLogsData = {};
let errorLogsTimestampData = [];
const Detail = ({ vehicleData, vehicleStatus }) => {
  const [metadata, setData] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [dataOne, setDataOne] = useState([]);
  const [dataTwo, setDataTwo] = useState([]);
  const [dataThree, setDataThree] = useState([]);
  const [createdDateData, setCreatedDateData] = useState();

  const data = [];
  const data2 = [];
  const data3 = [];
  let createddate = "";
  const handleClickError = () => {
    setIsOpen(!open);
  };
  useEffect(() => {
    if (vehicleData.status === 200) {
      let timestamp = vehicleData.map.timestamp[0];
      let date = new Date(timestamp);
      createddate = date.toISOString().split("T")[0];
      setCreatedDateData(createddate);
      let errorData = [];
      const sum = Object.values(vehicleData.report.errorCodes) // Get an array of values from the object
        .filter((value) => typeof value === "number") // Filter out non-numeric values
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      //Error Logs data
      errorLogsData = vehicleData.errordetail;
      errorLogsTimestampData = Object.keys(errorLogsData);
      //Length of the object for calculation of recieved records
      let recievedRecords = vehicleData.map.timestamp.length;
      let first_ping = vehicleData.map.timestamp[recievedRecords - 1];
      let last_ping = vehicleData.map.timestamp[0];
      let date1 = new Date(first_ping);
      let date2 = new Date(last_ping);
      let diffInMs = date2 - date1;
      let diffInMin = Math.floor(diffInMs / (1000 * 60));
      let actualRecords = Math.floor(diffInMin / 10);
      let reportData = {
        name: "Distance Travelled",
        value:(vehicleData.report.totaldistance).toFixed(2) +" Km",
        image: DistanceTravelled,
      };
      data.push(reportData);
      reportData = {};
      reportData = {
        name: "Average Speed",
        value: vehicleData.report.averageSpeed.toFixed(2).toString() + " Km/hr",
        image: AverageSpeed,
      };
      data.push(reportData);
      reportData = {};
      reportData = {
        name: "Peak Speed",
        value: vehicleData.report.peakSpeed.toString() + " Km/hr",
        image: PeakCurrent,
      };
      data.push(reportData);
      setDataOne(data);

      //page 1 Data in vehicle details
      let reportData2 = {
        name: "Vehicle live status",
        value: vehicleStatus.vehiclestatus,
        image: ErrorCount,
      };
      data2.push(reportData2);
      reportData2 = {};
      reportData2 = { name: "Battery status", value: vehicleStatus.batteryStatus, image: RecievedRecords };
      data2.push(reportData2);
      reportData2 = {};
      reportData2 = { name: "Error Count", value: sum, image: ErrorCount };
      data2.push(reportData2);
      reportData2 = {};
      setDataTwo(data2);
      //page three data vehicles
      let reportData3 = {};
      reportData3 = {
        name: "Peak Current",
        value: vehicleData.report.peakCurrent.toString() + " A",
        image: PeakCurrent,
      };
      data3.push(reportData3);
      reportData3 = {};
      reportData3 = {
        name: "Average Current",
        value: vehicleData.report.averageCurrent.toFixed(2).toString() + " A",
        image: PeakCurrent,
      };
      data3.push(reportData3);
      reportData3 = {};
      reportData3 = {
        name: "Received records",
        value: recievedRecords.toString(),
        image: RecievedRecords,
      };
      data3.push(reportData3);
      setDataThree(data3);
    }
  }, [vehicleData, vehicleStatus]);
  const width = 74;
  const left = 100 - width;
  const colorStyle = {
    width: `${width}%`,
    backgroundColor: `#2C988F`,
    borderRadius: `10px 0px 0px 10px`,
    display: `flex`,
  };
  let render;
  const handleTemp = () => {
    setIsOpen(false);
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Carousel infinite={false} className="Carousel" arrows {...settings}>
        <div className="to-center">
          <div className="vd-second-page">
            {dataTwo &&
              dataTwo.map((field, idx) => (
                <React.Fragment key={idx}>
                  <div className="vd-img" >
                    <img src={field.image} alt="" />
                  </div>
                  <div
                    className={
                      field.name === "Error Count" ? "vd-name-new" : "vd-name"
                    }
                    onClick={
                      field.name === "Error Count"
                        ? handleClickError
                        : undefined
                    }
                  >
                    {field.name}
                  </div>
                  <div className="vd-value">{field.value}</div>
                </React.Fragment>
              ))}
          </div>
        </div>
        <div className="to-center">
          <div className="vd-second-page">
            {dataOne &&
              dataOne.map((field, idx) => (
                <React.Fragment key={idx}>
                  <div className="vd-img" >
                    <img src={field.image} alt="" />
                  </div>
                  <div
                    className={
                      field.name === "Error Count" ? "vd-name-new" : "vd-name"
                    }
                  >
                    {field.name}
                  </div>
                  <div className="vd-value">{field.value}</div>
                </React.Fragment>
              ))}
          </div>
        </div>
        <div className="to-center">
          <div className="vd-second-page">
            {dataThree &&
              dataThree.map((field, idx) => (
                <React.Fragment key={idx}>
                  <div className="vd-img" >
                    <img src={field.image} alt="" />
                  </div>
                  <div
                    className={
                      field.name === "Error Count" ? "vd-name-new" : "vd-name"
                    }
                  >
                    {field.name}
                  </div>
                  <div className="vd-value">{field.value}</div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </Carousel>
      {open && (
        <div className="pop-up">
          <div className="close">
            <img className="cross" src={arrow} alt="" onClick={handleTemp} />
          </div>
          <div className="error-title">Error Code</div>
          <div className="table-list">
            <div className="table-list-row">
              <div>TIMESTAMPS</div>
              <div>ERROR MESSAGES</div>
              {errorLogsTimestampData.map((item) => {
                return (
                  <>
                    {errorLogsData[item].map((message) => {
                      return (
                        <>
                          <div className="table-list-column">{item}</div>
                          <div className="table-list-column">{message}</div>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Detail;
