import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import Form from "../../../Components/Form/Form";
import navData from "../../../service/navigation";
import loadash from "lodash";
import Loader from "../../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { setCustomers } from "../../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData from "../../../service/apiUtils";

const LoginID = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/customer/create-login");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);
  const [body, setBody] = useState(null);
  const [loader, setLoader] = useState(true);
  const [customer, setCustomer] = useState(
    useSelector((state) => state.customer.customers)
  );
  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[2].class = true;

  const formData = [
    {
      name: "Select Customer",
      type: "select",
      placeholder: "Select Customer",
      search: true,
      option: customer,
    },
    {
      name: "Customer Location",
      type: "select",
      placeholder: "Location",
      search: true,
    },
    { name: "Role", type: "input", attr: "text", placeholder: "Admin" },
    { name: "Username", type: "input", attr: "text", placeholder: "Username" },
    {
      name: "Password",
      type: "input",
      placeholder: "Password",
      attr: "password",
    },
  ];

  useEffect(() => {
    const endpoint = "customers"; // Endpoint to fetch
    fetchApiData(endpoint)
      .then((data) => {
        let customerOptions = convertArray(data.customers);
        setCustomer(customerOptions);
        dispatch(setCustomers(customerOptions));
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const handleBodyData = (e) => {
    setBody(e);
  };

  return (
    <>
      {loader && <Loader />}
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form
        heading={"Register LoginId"}
        data={formData}
        formName={"customerSignUp"}
        bodydata={handleBodyData}
      />
      <Footer />
    </>
  );
};

export default LoginID;
