import React from "react";
import "./dashboard.css"
import Unavailable  from "../../assets/unavailableData.png"
function Dashboard() {
    return(
        <div className="dashboard-main">
            <img src = {Unavailable} width={70} alt = ""/>
            <label className="dash-heading">NO DATA AVAILABLE</label>
            <label className="dash-content">Please select a VIN<br/> and date to view data</label>
        </div>
    );
}

export default Dashboard;