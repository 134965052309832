import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import loadash from "lodash";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Form from "../../Components/Form/Form";
import navData from "../../service/navigation";
import Loader from "../../Components/Loader/Loader";

const ParameterReport = () => {
  //user authentication code
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [loader, setLoader] = useState(true);
  const loggedInUser = localStorage.getItem("authenticated");
 

  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/parameter_report");
    } else {
      navigate("/app");
    }
  }, [authenticated]);

  const option = useSelector((state) => state.vin.vin);
  function convertArrayToObject(arr) {
    return arr.map((item) => {
      return { value: item, label: item };
    });
  }
  const availableVins = convertArrayToObject(option);
  let fields = loadash.cloneDeep(navData);
  fields[2].class = true;

  const formData = [
    {
      name: "Select Parameters",
      type: "select",
      placeholder: "Select Parameters",
      search: true,
      
    },
    {
      name: "Select VIN",
      type: "select",
      placeholder: "Select VIN",
      search: true,
      option:availableVins,
      condition:"Parameters"
    },
    {
      name:"Start Date & Time",
      type:"datetimepicker",
      placeholder:"Start Date & Time"
    },
    {
      name:"End Date & Time",
      type:"datetimepicker",
      placeholder:"End Date & Time"
    }
  ];

  return (
    <>
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form
        heading={"Vehicle Report"}
        data={formData}
        formName={"ParametersReport"}
      />
      <Footer />
    </>
  );
};

export default ParameterReport
