import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import loadash from "lodash";

import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Form from "../../Components/Form/Form";
import navData from "../../service/navigation";
import { setCustomers } from "../../redux/slices/customersSlice";
import fetchApiData from "../../service/apiUtils";
import Loader from "../../Components/Loader/Loader";

const AssignID = () => {
  //user authentication code
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [loader, setLoader] = useState(true);
  const customer = useSelector((state) => state.customer.customers);
  const loggedInUser = localStorage.getItem("authenticated");
  const [customerList, setCustomerList] = useState(
    useSelector((state) => {
      return state.customer.customers;
    })
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/assign_pickerID");
    } else {
      navigate("/app");
    }
  }, [authenticated]); 

  //if customerList is not present
  useEffect(() => {
    const url = "customers"; //endpoint to fetch
    if (!customerList) {
      fetchApiData(url)
        .then((data) => {
          let customerOptions = convertCustomerListArray(data.customers);
          setCustomerList(customerOptions);
          dispatch(setCustomers(customerOptions));
          setLoader(false);
        })
        .catch((error) => {
          alert("some Error!");
        });
    } else {
      setLoader(false);
    }
  }, [customerList]);

  function convertCustomerListArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  useEffect(() => {
    const url = "moptrouser"; //endpoint to fetch

    if (!customerList) {
      fetchApiData(url)
        .then((data) => {
          let customerOptions = convertCustomerListArray(data.customers);
          setCustomerList(customerOptions);
          dispatch(setCustomers(customerOptions));
          setLoader(false);
        })
        .catch((error) => {
          alert("Some Error!");
          console.error("Error:", error);
        });
    } else {
      setLoader(false);
    }
  }, [customerList]);

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[4].class = true;
  const formData = [
    {
      name: "Select Customer",
      type: "select",
      placeholder: "Select Customer",
      search: true,
      option: customer,
    },
    {
      name: "Customer Location",
      type: "select",
      placeholder: "Location",
      search: true,
    },
    {
      name: "PickerName",
      type: "input",
      attr: "text",
      placeholder: "PickerName",
    },
    { name: "PickerID", type: "input", attr: "text", placeholder: "PickerID" },
    {
      name: "Password",
      type: "input",
      placeholder: "Password",
      attr: "password",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form
        heading={"Assign PickerID "}
        data={formData}
        formName={"assignID"}
      />
      <Footer />
    </>
  );
};

export default AssignID;
