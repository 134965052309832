const getBaseUrl = () => {
  const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_BASE_URL_${env}`];
};

const getApiKey = () => {
  const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_KEY_${env}`];
};

const fetchApiData = async (endpoint) => {
  const url = getBaseUrl() + endpoint;
  const key = getApiKey();

  const headers = {
    "Content-Type": "application/json",
    ZCFKEY: key,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (response.status === 404) {
      return { status: 404 };
    }

    if (!response.ok) {
      throw new Error("Error: Not found!");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const sendPostRequest = async (endpoint, bodydata) => {
  const url = getBaseUrl() + endpoint;
  const key = getApiKey();

  const headers = {
    "Content-Type": "application/json",
    ZCFKEY: key,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(bodydata),
    });

    if (response.status === 201) {
      return "Registration Successful!";
    }else if (response.status===200){
      const responseData=await response.json()
      return responseData
    } 
    else if (response.status === 500) {
      return "Customer Already Exists!";
    } else {
      return "Registration Unsuccessful!";
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchAvailableVinNumber = async (endpoint) => {
  const url = getBaseUrl() + endpoint;
  const key = getApiKey();
  const headers = {
    "Content-Type": "application/json",
    ZCFKEY: key,
  };
 
  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  try {
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to generate VIN");
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchApiData;
