// eventLogSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logVin: null
}

const eventLogSlice = createSlice({
    name: "eventLog",
    initialState,
    reducers: {
        setLog: (state, action) => {
            state.logVin = action.payload;
        }
    }
});

export default eventLogSlice.reducer;
export const { setLog } = eventLogSlice.actions;

