import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import paramOpen from "../../assets/parameter_open.png";
import paramClose from "../../assets/parameter_close.png";
import { useSelector } from "react-redux";
import "./graph.css";

export default function ApexChart({
  data,
  param,
  selectedParameter,
  color,
  timestampData,
  ident,
  val,
}) {
  const parameters = useSelector((state) => state.param.parameters);
  const mapData = data;
  const [selectedOption, setSelectedOption] = useState(0);
  const [chartTitle, setChartTitle] = useState(parameters[selectedOption]);
  const [mapSeries, setMapSeries] = useState(mapData["voltage"]);

  const units = ["V", "%", "A", "A", "Km", "A", "Km/h", "Km/h", "Km/h", "°C", "°C", "°C"];
  const alias = {
    AvgCurrent: "Avg Current (Amps)",
    voltage: "Voltage (Volts)",
    soc: "Battery %",
    MotorTemperaturePeak: "Motor Temperature (°C) ",
    ControllerTemperaturePeak: "Controller Temperature (°C)",
    BatteryTemperaturePeak: "Battery Temperature (°C)",
    PeakCurrent: "Peak Current (Amps)",
    PeakSpeed: "Peak Speed (Km/h)",
    distance: "Distance (Km)",
    AvgSpeed: "Avg Speed (Km/h)",
    AvgAbsSpeed: "Avg Abs Speed (Km/h)",
    AvgAbsCurrent: "Avg Abs Current (Amps)",
    iOLcountPeakWatts: "",
    iIgnCycles: "Ignition Cycles",
    iOLcountMaxWatts: "",
    iMobChargePercentage: "Mobile battery %",
    fBatVoltage: "Voltage",
    iOLcountPeakAmps: "",
    iStartStopCyclesByPulse: "Start Stop Cycles",
  };

  // iMotorTemp: "Motor Temp",
  // iBatteryTemp: "Battery Temp",
  // iControllerTemp: "Controller Temp",
  const [chartName, setChartName] = useState(alias[parameters[0]]);


  const maxMin = [
    [40, 60],
    [0, 100],
    [0, 25],
    [0, 20],
    [0, 3],
    [0, 25],
    [0, 25],
    [0, 25],
    [0, 25],
    [25, 100],
    [25, 100],
    [25, 100]
  ]
  let max = maxMin[selectedOption][1];
  let min = maxMin[selectedOption][0];
  const dates = timestampData.map((timestamp) => new Date(timestamp));

  // Sort dates in ascending order
  dates.sort((a, b) => a - b);

  // Get earliest and latest dates
  const [isOpen, setIsOPen] = useState(false);

  let chartTitleData = "";
  //Below Code is to load intial graph data and ident is diff the graph
  if (param === undefined) {
  } else {
    chartTitleData = parameters[selectedOption];
  }
  if (mapSeries === undefined) {
    setMapSeries([]);
  }
  const [temp, setTemp] = useState({
    name: chartName,
    type: "area",
    data: mapSeries,
  });

  let series = [
    {
      name: chartName,
      type: "area",
      data: mapSeries,
    },
  ];
  const options = {
    noData: {
      text: `No Data Available for ${chartName}`,
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "white",
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    },

    chart: {
      background: "rgba(0, 0, 0, 0)",
      opacity: "0.22",
      foreColor: "#FEFEFE",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },

        export: {
          csv: {
            filename: "gdt_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "gdt_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          png: {
            filename: "gdt_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
        autoSelected: "zoom",
      },
    },
    colors: [color],
    fill: {
      colors: color,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 90, 100],
        gradientToColors: [color],
      },
    },
    grid: {
      show: true,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0.5,
        },
      },
      borderColor: "#90A4AE",
      strokeDashArray: 2,
    },
    markers: {
      size: 2,
      colors: color,
      strokeColors: color,
      strokeWidth: 2,
      strokeOpacity: 0.8,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
      dashArray: 0,
    },
    xaxis: {
      type: "datetime",
      categories: timestampData,
      labels: {
        format: "HH:mm",
        datetimeUTC: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "grey",
      },
      title: {
        text: "Timestamp",
        style: {
          fontSize: "1 rem",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
      // max: '2023-04-28 00:00:00',
      // min: '2023-04-27 00:00:00'
    },

    yaxis: {
      opposite: false,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "grey",
      },
      title: {
        text: chartName,
        style: {
          fontSize: "1 rem",
          fontFamily: "Montserrat",
          fontWeight: 400,
        },
      },
      min: min,
      max: max,
    },
    tooltip: {
      style: {
        fontFamily: "Montserrat",
      },
      theme: "dark",
      x: {
        show: true,
        format: "dd MMM HH:mm",
      },
      y: {
        show: false,
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return '<div class="apex_tooltip">' + value + " " + units[selectedOption] + "</div>";
        },
      },
    },
  };
  const handleClick = () => {
    setIsOPen(!isOpen);
  };
  const handleBodyClick = (event) => {
    setChartName(alias[parameters[event]]);
    setChartTitle(parameters[event]);
    setSelectedOption(event);
    setMapSeries(mapData[parameters[event]]);
  };
  return (
    <>
      {series && (
        <div className="first-map">
          <Chart
            key={data}
            options={options}
            series={series}
            type="area"
            width={isOpen ? "85%" : "95%"}
            transition="0.5s ease"
            height="250"
          />
        </div>
      )}
      {!isOpen && (
        <div className="select-parameter">
          <img
            src={paramOpen}
            alt=""
            width={6}
            onClick={handleClick}
            className="prm_img_open"
          />
          <div id="text">Click to select parameter</div>
        </div>
      )}
      {isOpen && (
        <div
          style={{ transition: "width 0.5s ease" }}
          className="select-option"
        >
          <img
            src={paramClose}
            alt=""
            width={6}
            onClick={handleClick}
            className="prm_img_close"
          />
          PARAMETER
          <ul className="options">
            {parameters.map((option, idx) => (
              <li
                className={
                  idx === selectedOption ? "selected-param" : "parameter-option"
                }
                key={option}
                idx={idx}
                onClick={function () {
                  handleBodyClick(idx);
                }}
              >
                {alias[option]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}