import React from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import LiveDashboard from "./View/Dashboard/LiveDashboard";
import Dashboard from "./View/Dashboard/dashboard";
import Login from "./Components/Login/Login";
import Assign from "./View/Assign/Assign";
import CustRegister from "./View/Customer/Register/Register";
import LocationRegister from "./View/Location/Register/Register";
import CustLoginID from "./View/Customer/CreateLoginID/CreateLoginID";
import Logs from "./View/Logs/Logs";
import AssignID from "./View/Vehicle/AssignID"
import ParameterReport from "./View/Vehicle/ParameterReport";
import VinGenerator from "./View/Vehicle/VinGenerator"

//Version PI_2023_10 UPDATED RELEASED VERSION 0.2.10
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route exact path="/app/livedashboard" element={<LiveDashboard/>}/>
          <Route exact path="/app/dashboard" element={<Dashboard />} />
          <Route exact path="/app" element={<Login />} />
          <Route
            exact
            path="/app/customer/register"
            element={<CustRegister />}
          />
          <Route
            exact
            path="/app/customer/create-login"
            element={<CustLoginID />}
          />
          <Route
            exact
            path="/app/location/register"
            element={<LocationRegister />}
          />
          <Route exact path="/app/logs" element={<Logs />} />
          <Route exact path="/app/assign_customer" element={<Assign />} />
          <Route exact path="/app/assign_pickerID" element={<AssignID />} />
          <Route exact path="/app/vin-generator" element={<VinGenerator />} />
          <Route exact path="/app/parameter_report" element={<ParameterReport />} />
          <Route exact path="*" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
