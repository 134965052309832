import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vehicleTypeOptions: "",
  vehicleCategoryOptions: "",
  generatedVIN: "",
};

const vinSlice = createSlice({
  name: "vin",
  initialState,
  reducers: {
    setVehicleType: (state, action) => {
      state.vehicleTypeOptions = action.payload;
    },
    setVehicleCategory: (state, action) => {
      state.vehicleCategoryOptions = action.payload;
    },
    generateVIN: (state) => {
      state.generatedVIN = `${state.vehicleTypeOptions}${state.vehicleCategoryOptions}12345`;
    },
  },
});
export default vinSlice.reducer;
export const { setVehicleType, setVehicleCategory, generateVIN } =
  vinSlice.actions;
