import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMapData } from "../../redux/slices/mapDataSlice";
import { setVin } from "../../redux/slices/vinReducer";
import { setVinNo, setDate } from "../../redux/slices/currVinSlice";

//import the Style
import "./body.css";
//Components Import
import Dropdown from "./Dropdown";
import Dashboard from "./DashBoard";
import DatePicker from "./DatePicker";
import VehicleData from "./VehicleDetail";
import Graphs from "./Graphs.js";
import fetchApiData from "../../service/apiUtils";
//Intializing varaibles
let timestampTemp = [];
let graphIdent = [1, 2];
// let max
function Body() {
  const dispatch = useDispatch();
  const color = "#1D9898";
  const firstcolor = "#C210DB";
  const [selectedOption, setSelectedOption] = useState(null);

  // Setting up the avaiable dates for the datepicker
  const [availableDates, setAvailableDates] = useState(null);

  const [selectedParameter, setSelectedparamaeter] = useState();
  const [selectedSecondParameter, setSecondSelectedparamaeter] = useState();
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedDate,setSelectedDate]=useState(null)
  const [dropdownData, setDropdownData] = useState(
    useSelector((state) => state.vin.vin)
  );
  const [isValid, setIsValid] = useState(false);
  const [vehicleDetailsData, setVehicleDetailsData] = useState([]);
  const [timestampData, setTimestampData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [vehicleStatus, setVehicleStatus] = useState([]);
  // mapdata bug fixing 29-08-2023
  const [graphData, setGraphData] = useState(null);
  const placeholder = "SELECT DATE";  

  const swap = (i, j, arr) => {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
    return arr;
  };
  const fetchData = async () => {
    const url = "vehicles";
    try {
      const data = await fetchApiData(url);
      dispatch(setVin(data.vins));
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [])
  

  let jsonData = {};
  const handleSelectVin = (event) => {
    setSelectedOption(event);
    dispatch(setVinNo(event));
  };

  const handleSelectDate = (event) => {
    setSelectedDate(event);
    dispatch(setDate(event));
  };

  const handleSubmit = async () => {
    if (selectedOption === null || selectedDate === null)
      alert("Fill all the fields");
    else {
      setGraphData(null);
      setVehicleDetailsData(null);
      setLoader(true);
      await fetchVehicleStatus();
      await fetchData2();
      if (jsonData.status === 401) alert("Enter VIN and date");
      else if (jsonData.status === 404) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };

  //Below function is used to populate dropdown data that is to select vin number
  function convertDateFormat(dateString) {
    var dateParts = dateString.split("-");
    var day = dateParts[0];
    var month = dateParts[1];
    var year = dateParts[2];
    var convertedDate = year + "-" + month + "-" + day;
    return convertedDate;
  }
  //fetching vehicle status from API
  const fetchVehicleStatus = async () => {
    // Construct the endpoint using the parameters
    const endPoint = `status?vin=${selectedOption}`;
    try {
      // Call fetchApiData function to make the API request
      const response = await fetchApiData(endPoint);
      if (response.status === 200) {
        jsonData = response;
        setVehicleStatus(response);
      } else if (response.status === 404) {
        setVehicleDetailsData(null);
      }
    } catch (error) {
      alert(error);
    }
  };
  // update the vehicle status for every 30 seconds
  useEffect(() => {
    if(selectedOption){
    const updateVehicleStatus = setInterval(fetchVehicleStatus, 30000);
    return () => clearInterval(updateVehicleStatus);
    }
  }, [selectedOption]);

  const fetchData2 = async () => {
    // dispatch(setMapData(null));
    const time = selectedDate.$d;
    const formattedDate = time
      .toLocaleDateString("en-GB", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      })
      .replace(/\//g, "-");
    const currentDate = convertDateFormat(formattedDate);
    // Construct the endpoint using the parameters
    const endPoint = `dashboard?vin=${selectedOption}&date=${currentDate}`;
    try {
      // Call fetchApiData function to make the API request
      const apiData = await fetchApiData(endPoint);
      if (apiData.status === 404) {
        dispatch(setMapData(null));
        setVehicleDetailsData(null);
        setGraphData(null);
        setIsValid(false);
      } else if (apiData.status === 200) {
        jsonData = apiData;
        setGraphData(apiData.map);
        dispatch(setMapData(apiData.map));
        setVehicleDetailsData(apiData);
        timestampTemp = jsonData.map.timestamp;
        setTimestampData(timestampTemp);
        setIsValid(true);
      } else {
        timestampTemp = [];
        jsonData = apiData;
        setVehicleDetailsData(apiData);
        setTimestampData(timestampTemp);
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      alert(error);
      // Handle error state or notify the user
    } finally {
      setLoader(false);
    }
  };

  const handleDateOpen = (event) => {
    setDateOpen(event);
  };

  const handleSelectedParameter = (event) => {
    setSelectedparamaeter(event);
  };

  const handleSecondSelectedParameter = (event) => {
    setSecondSelectedparamaeter(event);
  };
  return (
    <div className="main-body">
      {loader && (
        <div style={{ display: "flex" }} id="main-alert-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="vin">
        <div className="vehicle-number">
          <Dropdown selectedVin={handleSelectVin} vin={selectedOption} />
        </div>
        <div className={dateOpen ? "open-vehicle-date" : "vehicle-date"}>
          <DatePicker
            onSelect={handleSelectDate}
            open={handleDateOpen}
            date={selectedDate}
            placeholder={placeholder}
          />
        </div>
        <button className="vin-submit" onClick={handleSubmit}>
          SUBMIT
        </button>
      </div>
      <div className="data-one">
        {vehicleDetailsData && isValid ? (
          <>
            {
              <VehicleData
                vehicleData={vehicleDetailsData}
                vehicleStatus={vehicleStatus}
              />
            }
          </>
        ) : (
          <Dashboard />
        )}
      </div>
      <div className="data-two">
        {graphData && isValid ? (
          <Graphs
            data={graphData}
            selectedParameter={handleSelectedParameter}
            param={selectedParameter}
            color={firstcolor}
            timestampData={timestampData}
            ident={graphIdent[0]}
            val={false}
            graphInfo={graphData}
          />
        ) : (
          <Dashboard />
        )}
      </div>
      <div className="data-three" key={graphData}>
        {graphData && isValid ? (
          <Graphs
            data={graphData}
            color={color}
            selectedParameter={handleSecondSelectedParameter}
            param={selectedSecondParameter}
            timestampData={timestampData}
            ident={graphIdent[1]}
            val={false}
            graphInfo={graphData}
          />
        ) : (
          <Dashboard />
        )}
      </div>
    </div>
  );
}

export default Body;
