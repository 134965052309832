const statesData = [
    {
      "value": "Andaman and Nicobar Islands",
      "label": "Andaman and Nicobar Islands"
    },
    {
      "value": "Andhra Pradesh",
      "label": "Andhra Pradesh"
    },
    {
      "value": "Arunachal Pradesh",
      "label": "Arunachal Pradesh"
    },
    {
      "value": "Assam",
      "label": "Assam"
    },
    {
      "value": "Bihar",
      "label": "Bihar"
    },
    {
      "value": "Chandigarh",
      "label": "Chandigarh"
    },
    {
      "value": "Chhattisgarh",
      "label": "Chhattisgarh"
    },
    {
      "value": "Dadra and Nagar Haveli",
      "label": "Dadra and Nagar Haveli"
    },
    {
      "value": "Daman and Diu",
      "label": "Daman and Diu"
    },
    {
      "value": "Delhi",
      "label": "Delhi"
    },
    {
      "value": "Goa",
      "label": "Goa"
    },
    {
      "value": "Gujarat",
      "label": "Gujarat"
    },
    {
      "value": "Haryana",
      "label": "Haryana"
    },
    {
      "value": "Himachal Pradesh",
      "label": "Himachal Pradesh"
    },
    {
      "value": "Jammu and Kashmir",
      "label": "Jammu and Kashmir"
    },
    {
      "value": "Jharkhand",
      "label": "Jharkhand"
    },
    {
      "value": "Karnataka",
      "label": "Karnataka"
    },
    {
      "value": "Kerala",
      "label": "Kerala"
    },
    {
      "value": "Lakshadweep",
      "label": "Lakshadweep"
    },
    {
      "value": "Madhya Pradesh",
      "label": "Madhya Pradesh"
    },
    {
      "value": "Maharashtra",
      "label": "Maharashtra"
    },
    {
      "value": "Manipur",
      "label": "Manipur"
    },
    {
      "value": "Meghalaya",
      "label": "Meghalaya"
    },
    {
      "value": "Mizoram",
      "label": "Mizoram"
    },
    {
      "value": "Nagaland",
      "label": "Nagaland"
    },
    {
      "value": "Odisha",
      "label": "Odisha"
    },
    {
      "value": "Puducherry",
      "label": "Puducherry"
    },
    {
      "value": "Punjab",
      "label": "Punjab"
    },
    {
      "value": "Rajasthan",
      "label": "Rajasthan"
    },
    {
      "value": "Sikkim",
      "label": "Sikkim"
    },
    {
      "value": "Tamil Nadu",
      "label": "Tamil Nadu"
    },
    {
      "value": "Telangana",
      "label": "Telangana"
    },
    {
      "value": "Tripura",
      "label": "Tripura"
    },
    {
      "value": "Uttar Pradesh",
      "label": "Uttar Pradesh"
    },
    {
      "value": "Uttarakhand",
      "label": "Uttarakhand"
    },
    {
      "value": "West Bengal",
      "label": "West Bengal"
    }
  ];
  
  module.exports = statesData;
  