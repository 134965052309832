import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import paramReducer from "./slices/paramReducer";
import diagHeadings from "./slices/diagHeadings";
import vinReducer from "./slices/vinReducer";
import mapDataSlice from "./slices/mapDataSlice";
import currVinSlice from "./slices/currVinSlice";
import customersSlice from "./slices/customersSlice";
import availableVinsSlice from "./slices/availableVinsSlice";
import eventLogsSlice from "./slices/eventLogsSlice";

const persistConfig = {
    key: 'root',
    storage,
  };

const persistedParam = persistReducer(persistConfig, paramReducer);
const persistedDiag = persistReducer(persistConfig, diagHeadings);
const persistedVin = persistReducer(persistConfig, vinReducer);
const persistedMapData = persistReducer(persistConfig, mapDataSlice);
const persistedCustomer = persistReducer(persistConfig, customersSlice);
const persistedAvailVins = persistReducer(persistConfig, availableVinsSlice);
const persistedEventLogs = persistReducer(persistConfig,eventLogsSlice)

export const store = configureStore({
    reducer: {
        param: persistedParam,
        diag: persistedDiag,
        vin: persistedVin,
        mapData: persistedMapData,
        vinDetails: currVinSlice,
        customer: persistedCustomer,
        availVins: persistedAvailVins,
        eventLog:persistedEventLogs
    },
    middleware: [thunk],
    devTools: process.env.REACT_APP_ENV === "DEVELOPMENT" ? true : false
})

export const persistor = persistStore(store);