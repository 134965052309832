import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/INDEX_LOGO.png";
import profile from "../../assets/Profile.png";
import settings from "../../assets/settings.png";
import w2hLogo from "../../assets/w2hLogo.png";
import "./head.css";

const Head = function () {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (loggedInUser) {
      localStorage.clear();
    };
    window.location.reload();
    navigate("/app");
  };
  const handleIconClick = () => {
    setDropdown(!dropdown);
  };
  const handleOptionClick = (option) => {
    if (option === "Logout") {
      localStorage.clear();
      document.getElementById("main-alert-box").style.display = "flex";
      setTimeout(handleClick, 3000);
    };
    setDropdown(false);
  };

  const handleSettingClick = () => {
    const url = window.location.href;
    if (url.includes("/setting")) {
      navigate("/app/dashboard");
    } else navigate("/app/setting");
  };
  return (
    <div className="ind-title">
      <div id="main-alert-box">
        <span className="loader"></span>
      </div>
      <div
        style={{
          position: "absolute",
          left: "0",
          zIndex: "15",
        }}
      >
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <div style={{verticalAlign:"middle"}}>  
          <img src={logo} width={35} style={{paddingLeft:"0.5rem"}}  alt="" />
          </div>
      <h2 style={{padding:"0.2rem"}}>W2H Index</h2>
        </div>
      </div>
      <div className="ind-profile">
        <img
          src={profile}
          style={{ height: "25px" }}
          alt=""
          onClick={handleIconClick}
        />
      </div>
      {dropdown && (
        <div className="ind-drop">
          <div onClick={() => handleOptionClick("Logout")}>Log Out</div>
        </div>
      )}
    </div>
  );
};
export default Head;
