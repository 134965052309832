import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import "./dropdown.css";

const Dropdown = ({ selectedVin, vin }) => {
  const option = useSelector((state) => state.vin.vin);
  function convertArrayToObject(arr) {
    return arr.map((item) => {
      return { value: item, label: item };
    });
  }
  

// Sort the option array in descending order
const sortedOption = option.slice().sort((a, b) => {
  const vin1 = a.slice(-9);
  const vin2 = b.slice(-9);
  return vin2.localeCompare(vin1);
});

  const data = convertArrayToObject(sortedOption);
  const onChange = (value) => {
    selectedVin(value);
  };

  return (
    <div className="dropdown-select" style={{ width: "100%" }}>
      <Select
        size="large"
        showSearch
        placeholder="SELECT VIN"
        optionFilterProp="children"
        value={vin}
        onChange={onChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={data}
      />
    </div>
  );
};

export default Dropdown;
