import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import Form from "../../../Components/Form/Form";
import navData from "../../../service/navigation";
import loadash from "lodash";
import Loader from "../../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCustomers } from "../../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData from "../../../service/apiUtils";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/location/register");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);
  const [body, setBody] = useState(null);
  const [loader, setLoader] = useState(true);
  const [customer, setCustomer] = useState(useSelector((state) => state.customer.customers));
  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[1].class = true;

  useEffect(() => {
    const endpoint = "customers"; // Endpoint to fetch
    fetchApiData(endpoint)
      .then((data) => {
        let customerOptions = convertArray(data.customers);
        setCustomer(customerOptions);
        dispatch(setCustomers(customerOptions));
        setLoader(false);
      })
      .catch((error) => {
        alert("Some Error!");
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const formData = [
    {
      name: "Customer Name",
      type: "select",
      placeholder: "Name",
      search: true,
      option: customer
    },
    {
      name: "Location Name",
      type: "input",
      placeholder: "Location",
      attr: "text",
    },
    { name: "Country", type: "select", placeholder: "Country", search: true, },
    { name: "State", type: "select", placeholder: "State", search: true },
    { name: "City", type: "select", placeholder: "City", search: true },
    { name: "Zipcode", type: "input", placeholder: "Zipcode", attr: "text" },
    { name: "Customer Address", type: "textArea", placeholder: "Address" },
  ];

  const handleBodyData = (e) => {
    setBody(e);
  };
  return (
    <>
      {loader && <Loader />}
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      {customer && <Form
        heading="Register Location"
        data={formData}
        formName={"locationName"}
        bodydata={handleBodyData}
      />}
      <Footer />
    </>
  );
};

export default Register;
