import React, { useState, useEffect } from "react";
import "./form.css";
import {
  Select,
  Input,
  DatePicker,
  InputNumber,
  TimePicker,
  Tooltip,
} from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import state from "../../assets/states";
import parameters from "../../assets/parameters";
import city from "../../assets/cities";
import Loader from "../Loader/Loader";
import fetchApiData from "../../service/apiUtils";
import { sendPostRequest } from "../../service/apiUtils";
import info from "../../assets/info.png";
import { getParameterUnit } from "../../assets/parameterunit";
import { fetchAvailableVinNumber } from "../../service/apiUtils";

const Form = (props) => {
  const [customerName, setCustomerName] = useState(null);
  const [states, setStates] = useState(state);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [date, setDate] = useState(null);
  const [name, setName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [vertical, setVertical] = useState(null);
  const [custName, setCustName] = useState(null);
  const [zipcode, setZipCode] = useState(null);
  const [custLocation, setCustLocation] = useState(null);
  const [userName, setuserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [addCustomer, setAddCustomer] = useState(null);
  const [deploymentDate, setDeploymentDate] = useState(null);
  const [selectedVins, setSelectedVins] = useState([]);
  const [address, setAddress] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [locationID, setLocationID] = useState(null);
  const [custAddress, setCustAddress] = useState(null);
  const [role, setRole] = useState("Admin");
  const [loader, setLoader] = useState(false);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [locationOption, setLocationOption] = useState(null);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [parametersData, setParametersData] = useState({});
  const [isParameters, setIsParameters] = useState(false);
  const [updateVinNumber, setUpdateVinNumber] = useState(null);
  const [updatedCategory, setUpdatedCategory] = useState(null);
  const [currentStage,setCurrentStage]=useState()

  //state variables for Assign Picker ID Form
  const [pickerId, setpickerId] = useState(null);
  const [pickerName, setPickerName] = useState(null);
  const [pickerNameError, setPickerNameError] = useState("");
  const [item, setItem] = useState({
    type: "select",
    name: "VEHICLE TYPE" || "VEHICLE STAGE",
    value: "",
  });

  //state variables for generate vin form
  const [btnName, setBtnName] = useState(true);
  const [generatedVin, setGeneratedVin] = useState("");
  const [prodCode, setProdCode] = useState(null);
  const [vehicleCategory, setVehicleCategory] = useState(null);

  const disabledDate = (current, startDate) => {
    if (startDate) {
      return current && current < startDate;
    } else {
      // Disable dates before today
      return current && current < moment().startOf("day");
    }
  };

  // Disabling future times
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });
  // based on customer location is rendered
  useEffect(() => {
    if (customerName || custName) {
      if (props.heading !== "Register Location") {
        setCustLocation(null);
        setLocationOption(null);
        setLoader(true);
        const endpoint = `locations?custId=${customerName || custName}`; // Endpoint to fetch
        fetchApiData(endpoint)
          .then((data) => {
            if (data.customers) {
              const locationOptions = convertArray(data.customers);
              setLocationOption(locationOptions);
            }
            setLoader(false);
          })
          .catch((error) => {
            alert(error);
            setLoader(false);
            console.error(error);
          });
      }
    }
  }, [customerName, custName]);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.locName,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  useEffect(() => {
    if (selectedState) {
      let covertedArray = convertArrayToObjects(city[selectedState]);
      setCities(covertedArray);
    }
  }, [selectedState]);

  function convertArrayToObjects(inputArray) {
    const outputArray = inputArray.map((item) => {
      return { value: item, label: item };
    });

    return outputArray;
  }
  //Disabling future Date
  const disableEndDate = (currentDate) => {
    return currentDate && currentDate > moment().startOf();
  };

  // Handles date based on item name
  const handleDate = (name, e) => {
    switch (name) {
      case "Start Date":
        setDeploymentDate(e);
        break;
      case "End Date":
        setEndDate(e);
        break;
      case "Start Date & Time":
        setStartDateTime(getFormattedDateTime(e));
        break;
      case "End Date & Time":
        setEndDateTime(getFormattedDateTime(e));
        break;
      default:
        setDate(e);
        break;
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  // Handles address based on item name
  const handleTextArea = (name,e) => {
    if (name === "Address") setAddress(e);
    else setCustAddress(e);
    
  };
  const handlePasword = (name, e) => {
    setPassword(e);
  };
  // Handles inputs based on item name
  const handleInputText = (name, e) => {
    switch (name) {
      case "Add Customer":
        setAddCustomer(e);
        break;
      case "Name":
        setName(e);
        break;
      case "Website":
        setWebsite(e);
        break;
      case "Zipcode":
        setZipCode(e);
        break;
      case "Username":
        setuserName(e);
        break;
      case "Password":
        setPassword(e);
        break;
      case "Role":
        setRole(e);
        break;
      case "Add Location":
        setCustLocation(e);
        break;
      //Have to be removed further not using in this version
      case "Address":
        setLocationID(e);
        break;
      case "Location Name":
        setLocationID(e);
        break;
      case "PickerName":
        setPickerName(e);
        break;
      case "PickerID":
        setpickerId(e);
        break;
      default:
        break;
    }
  };
  
  // Handles selected options based on item name
  const handleSelect = (name, e) => {
    switch (name) {
      case "UPDATE STAGE":
        setUpdatedCategory(e);
        break;
      case "Select VIN":
        setSelectedVins(e);
        break;
      case "Vertical":
        setVertical(e);
        break;
      case "Customer Name":
        setCustName(e);
        break;
      case "Country":
        setSelectedCountry(e);
        break;
      case "State":
        setSelectedState(e);
        break;
      case "City":
        setSelectedCity(e);
        break;
      case "Location":
        setCustLocation(e);
        break;
      case "Role":
        setRole(e);
        break;
      case "Select Parameters":
        setSelectedParameters(e);
        break;
      case "Select Customer":
        setCustomerName(e);
        break;
      case "VEHICLE TYPE":
        let newProdCode;
        switch (e) {
          case "MOPTro SNAIL":
            newProdCode = "MOPSNL";
            break;
          case "MOPTro LITE ":
            newProdCode = "MOPLTE";
            break;
          case "MOPTro LITE + ":
            newProdCode = "MOPLTP";
            break;
          case "NXP400 ":
            newProdCode = "NXP400";
            break;
          case "NXP500 ":
            newProdCode = "NXP500";
            break;
          case "NXP750 ":
            newProdCode = "NXP750";
            break;
          default:
            newProdCode = "DEFAULT";
            break;
        }
        setProdCode(newProdCode);
        break;
      case "VEHICLE STAGE":
        setVehicleCategory(e);
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, [parametersData]);

  // data to be sent to the server
  const handleSubmit = async () => {
    setLoader(true);
    let bodydata = {};
    if (props.formName === "assign") {
      if (!deploymentDate || !custLocation || !selectedVins) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      let sDate = getFormattedDate(deploymentDate);

      if (endDate) {
        let eDate = getFormattedDate(endDate);
        bodydata = {
          locationId: custLocation,
          startDate: sDate + " " + startTime,
          endDate: eDate + " " + endTime,
          vehicleId: selectedVins,
        };
      } else {
        bodydata = {
          locationId: custLocation,
          startDate: sDate + " " + startTime,
          vehicleId: selectedVins,
        };
      }
    }
    if (props.formName === "customerName") {
      if (!name || !website || !vertical) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        name: name,
        website: website,
        vertical: vertical,
        actionName: props.formName,
      };
    }
    if (props.formName === "ParametersReport") {
      if (
        !selectedParameters ||
        !selectedVins ||
        !startDateTime ||
        !endDateTime
      ) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        parameters: selectedParameters,
        vin: selectedVins,
        startTime: startDateTime,
        endTime: endDateTime,
      };
    }
    if (props.formName === "locationName") {
      if (
        !custName ||
        !selectedCountry ||
        !selectedState ||
        !selectedCity ||
        !zipcode ||
        !locationID ||
        !custAddress
      ) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        custId: custName,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        zipcode: zipcode,
        actionName: props.formName,
        locName: locationID,
        address: custAddress,
      };
    }
    if (props.formName === "customerSignUp") {
      if (!userName || !password || !custLocation || !role) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        username: userName,
        password: password,
        locationId: custLocation,
        role: role,
        actionName: props.formName,
      };
    }
    let url = "register";
    if (props.formName === "assign") {
      url = "tagvehicles";
    }
    if (props.formName === "ParametersReport") {
      setIsParameters(true);
      setLoader(true);
      try {
        url = "parameters";
        const response = await sendPostRequest(url, bodydata);
        setLoader(false);
        setParametersData(response.parameterData);
      } catch (error) {
        alert(error);
      }
    }

    //Assign picker ID form rendering

    if (props.formName === "assignID") {
      if (pickerId && password && pickerName && custLocation) {
        bodydata = {
          username: pickerId,
          password: password,
          name: pickerName,
          id: custLocation,
          actionName: props.formName,
        };
      } else {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      url = "moptrouser";
    }
    if (props.formName === "vinGenerator") {
      if (!vehicleCategory && !prodCode && !generatedVin) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        newvin: generatedVin.newvin,
        prodCode: prodCode,
        vehicle_stage: vehicleCategory,
        id: generatedVin.id,
      };
      url = "registerVin";
    }
   
    if (props.formName === "updateVin") {
      if (!updatedCategory || !updateVinNumber) {
        setLoader(false);
        alert("Fill all the mandatory feilds!");
        return;
      }
      bodydata = {
        vehicleID: updateVinNumber,
        stage: updatedCategory
      };
      url = "/updateCategory";
    }
    setLoader(true);
    if (props.formName === "ParametersReport") {
      try {
        url = "parameters";
        const response = await sendPostRequest(url, bodydata);
        setLoader(false);
        setParametersData(response.parameterData);
      } catch (error) {
        alert(error);
      }
    } else {
      try {
        const message = await sendPostRequest(url, bodydata);
        if (props.formName === "updateVin") {
          alert(message.message)
        }
        else alert(message);
        window.location.reload();
      } catch (error) {
        alert("some error");
      } finally {
        setLoader(false);
      }
    }
  };
  const handleInputParameter = (value) => {
    if (value.length <= 6) {
      setSelectedParameters(value);
    }
  };

  const handleGenerateVin = async () => {
    setLoader(true);
    if (prodCode && vehicleCategory) {
      const endpoint = `generateVin?prodcode=${prodCode}`;
      try {
        const data = await fetchAvailableVinNumber(endpoint);
        setGeneratedVin(data);
        setLoader(false);
      } catch (error) {
        alert(error);
      }
    } else {
      setLoader(false);
      alert("prodCode or vehicleCategory is missing.");
    }
  };

  const handleCountry = (e) => {
    setSelectedCountry(e);
  };

  const handleState = (e) => {
    setSelectedCity(null);
    setSelectedState(e);
  };

  const handleCity = (e) => {
    setSelectedCity(e);
  };

  const handleZipcode = (e) => {
    setZipCode(e);
  };

  const handleLocation = (e) => {
    setCustLocation(e);
  };

  const handleTime = (name, e) => {
    if (name === "Start time") {
      setStartTime(getTimeStampFromData(e));
    } else {
      setEndTime(getTimeStampFromData(e));
    }
  };
  const pickerNameRegularExpression = /^[a-zA-Z0-9\s]*$/; //regular expression to check if picker name has any special charecters
  const handlePickerNameChange = (value) => {
    if (!pickerNameRegularExpression.test(value.target.value)) {
      setPickerNameError("Name cannot have special charecter");
    } else {
      setPickerNameError("");
    }
    setPickerName(value.target.value);
    const newValue = value.target.value;
    setItem({ ...item, value: newValue });
  };

  function getTimeStampFromData(data) {
    const hours = data.$H.toString().padStart(2, "0");
    const minutes = data.$m.toString().padStart(2, "0");
    const seconds = data.$s.toString().padStart(2, "0");
    const timeStamp = `${hours}:${minutes}:${seconds}`;
    return timeStamp;
  }

  function getFormattedDate(data) {
    const year = data.$y.toString();
    const month = (data.$M + 1).toString().padStart(2, "0");
    const day = data.$D.toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function getFormattedDateTime(data) {
    const year = data.$y.toString();
    const month = (data.$M + 1).toString().padStart(2, "0");
    const day = data.$D.toString().padStart(2, "0");
    const hours = data.$H.toString().padStart(2, "0");
    const minutes = data.$m.toString().padStart(2, "0");
    const seconds = data.$s.toString().padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }
  const handleLocationName = (e) => {
    setLocationID(e.target.value);
  };

  const [vinGen, setVinGen] = useState(true);

  const handleVINGen = () => {
    props.isVin(true);
    setVinGen(true);
  };

  const handleUpdateVin = () => {
    props.isVin(false);
    setVinGen(false);
  };
  
  //function to get the curret stage of the vehicle
  const handleVINNumber = (e) => {
    setUpdateVinNumber(e);
    if(e){
      setLoader(true);
      const endpoint = `vehiclestage?rowid=${e}`; // Endpoint to fetch
      fetchApiData(endpoint)
        .then((data) => {
            setCurrentStage(data.currentStage);
            setLoader(false);
        })
        .catch((error) =>{
          alert(error);
          setLoader(false);
          console.error(error);
        });
      setBtnName(false)
    }
  }
  return (
    <div className="main-form">
      {loader && <Loader />}
      <div className="form-body">
        {props.heading !== "VIN GENERATOR" && (
          <div className="heading">
            <h2>{props.heading}</h2>
          </div>
        )}
        {props.heading === "VIN GENERATOR" && (
          <div className="vin-gen-heading">
            <div
              className="vin-gen-sub-heading"
              style={{
                opacity: `${vinGen ? "1" : "0.8"}`,
                borderBottom: `${vinGen ? "2px solid #00b7a8" : ""}`,
              }}
              onClick={handleVINGen}
            >
              Vin Generator
            </div>
            <div
              className="vin-gen-sub-heading"
              style={{
                opacity: `${!vinGen ? "1" : "0.8"}`,
                borderBottom: `${!vinGen ? "2px solid #00b7a8" : ""}`,
              }}
              onClick={handleUpdateVin}
            >
              Update Vehicle Stage
            </div>
          </div>
        )}

        {props.data.map((item, idx) => {
          return (
            <div className="form-inputs" key={idx} style={{ gridTemplateColumns: !vinGen ? '1fr 1fr' : '1fr 1fr 1fr' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  rowGap: "2%",
                }}
              >
                <div style={{ width: "9rem", marginRight: "2rem" }}>
                  {item.name}
                  {item.name !== "End Date" && item.name !== "End Time" && (
                    <span className="imp"> *</span>
                  )}
                </div>
                {item.name === "Address" && (
                  <Tooltip
                    title="This feature is in development.
                  For now, enter the location number."
                    color="#1b3643"
                  >
                    <span style={{ cursor: "pointer" }}>
                      <img src={info} alt="info" width={20} />
                    </span>
                  </Tooltip>
                )}
              </div>
              {item.type === "datetimepicker" && (
                <div>
                  <DatePicker
                    style={{
                      width: "22rem",
                      border: "none",
                      paddingLeft: "0.5rem",
                      backgroundColor: "#1B3646",
                      color: "white",
                    }}
                    disabledDate={disableEndDate}
                    onChange={(e) => handleDate(item.name, e)}
                    className="cust-datepicker"
                    placeholder={item.placeholder}
                    showTime={{ format: "HH:mm" }}
                    showNow={false}
                  />
                </div>
              )}
              {item.type === "datepicker" && (
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                      border: "none",
                      paddingLeft: "0.5rem",
                      backgroundColor: "#1B3646",
                      color: "white",
                    }}
                    format="DD-MM-YYYY"
                    placement="bottomLeft"
                    allowClear={false}
                    showNow={true}
                    onChange={(e) => handleDate(item.name, e)}
                    className="cust-datepicker"
                    disabledDate={(current) =>
                      disabledDate(current, deploymentDate)
                    }
                    placeholder={item.placeholder}
                    disabledTime={disabledDateTime}
                    showToday={false}
                  />
                </div>
              )}
              {item.type === "textArea" && (
                <TextArea
                  onChange={(e) => handleTextArea(item.name, e.target.value)}
                  placeholder={item.placeholder}
                />
              )}
              {/* Below code is set picker name */}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name !== "Zipcode" &&
                item.name !== "pickerId" &&
                item.name === "PickerName" &&
                item.name !== "GENERATED VIN" && (
                  <div>
                    <Input
                      placeholder={item.placeholder}
                      value={pickerName}
                      onChange={handlePickerNameChange}
                      disabled={item.placeholder === "Role" ? true : false}
                    />
                    {pickerNameError && (
                      <div style={{ color: "red" }}>{pickerNameError}</div>
                    )}
                  </div>
                )}
              {/* Below code is set pickeriD */}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name === "pickerId" &&
                item.name !== "GENERATED VIN" && (
                  <div>
                    <Input
                      className="text-inp"
                      value={pickerId}
                      placeholder={item.placeholder}
                      onChange={(e) => {
                        handleInputText(item.name, e.target.value);
                      }}
                      autoComplete="new password"
                    />
                  </div>
                )}
              {/*below code is for current stage*/}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name === "CURRENT STAGE" &&
                item.name !== "Zipcode" &&
                item.name !== "pickerId" &&
                item.name !== "PickerName" &&
                item.name !== "GENERATED VIN" && (
                  <div>
                    <Input
                      className="text-inp"
                      style={{color:"white"}}
                      placeholder={item.placeholder}
                      disabled={btnName ? true : false}
                      value={currentStage}
                      readOnly={true}
                    />
                  </div>
                )}
              {/* Password handling */}
              {item.type === "input" && item.attr === "password" && (
                <div>
                  <Input.Password
                    className="form-inp"
                    placeholder={item.placeholder}
                    autoComplete="new-password"
                    onChange={(e) => handlePasword(item.name, e.target.value)}
                  />
                </div>
              )}
              {/* Inputs with normal texts */}
              {/* Here below code is to not rerender the field repeteadly*/}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name !== "Zipcode" &&
                item.name !== "Address" &&
                item.name !== "PickerName" &&
                item.name !== "pickerId" &&
                item.name !== "GENERATED VIN" &&
                item.name !== "CURRENT STAGE" &&(
                  <div>
                    <Input
                      className="text-inp"
                      placeholder={item.placeholder}
                      onChange={(e) =>
                        handleInputText(item.name, e.target.value)
                      }
                      disabled={item.name === "Role" ? true : false}
                      autoComplete="new password"
                    />
                  </div>
                )}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name === "Zipcode" &&
                item.name !== "GENERATED VIN" && (
                  <div>
                    <InputNumber
                      className="number-inp"
                      placeholder={item.placeholder}
                      // onChange={(e) => handleInputText(item.name, e.target.value)}
                      // disabled={item.name === "Role" ? true : false}
                      autoComplete="new password"
                      maxLength="6"
                      controls={false}
                      onChange={handleZipcode}
                      type="number"
                    />
                  </div>
                )}
              {/*Below field will be rendered if generated vin field is invoked */}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name === "GENERATED VIN" && (
                  <>
                    <div className="vin-text-btn">
                      <Input
                        placeholder={item.placeholder}
                        value={generatedVin.newvin}
                        disabled={btnName ? true : false}
                        readOnly={true}
                        name={item.name}
                      />
                    </div>
                    <button
                      className="gen-btn"
                      onClick={(e) => {
                        handleGenerateVin();
                        setBtnName(false);
                      }}
                    >
                      Generate Vin
                    </button>
                  </>
                )}
              {item.type === "input" &&
                item.attr === "text" &&
                item.name === "Address" &&
                item.name === "GENERATED VIN" && (
                  <div>
                    <Input
                      className="number-inp"
                      placeholder={item.placeholder}
                      onChange={handleLocationName}
                    />
                  </div>
                )}
              {item.type === "select" &&
                item.name !== "Country" &&
                item.name !== "State" &&
                item.name !== "Customer Location" &&
                item.name !== "Location" &&
                item.name !== "City" &&
                item.name !== "VEHICLE STAGE" &&
                item.name !== "SELECT VIN" &&
                item.name !== "Select Parameters" &&
                item.name !== "VEHICLE TYPE" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className={
                        item.condition ? "parameter-select" : "form-select"
                      }
                      placeholder={item.placeholder}
                      showSearch={item.search}
                      onChange={(e) => handleSelect(item.name, e)}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={item.option}
                      autoComplete="off"
                    ></Select>
                  </div>
                )}

              {item.type === "select" &&
                item.name === "Country" &&
                item.name !== "VEHICLE STAGE" &&
                item.name !== "VEHICLE TYPE" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className="form-select"
                      placeholder={item.placeholder}
                      showSearch={item.search}
                      value={"India"}
                      mode={item.multi && "multiple"}
                      onChange={handleCountry}
                      options={[
                        { value: "India", label: "India", selected: true },
                      ]}
                      autoComplete="off"
                    ></Select>
                  </div>
                )}
              {item.type === "select" &&
                item.name === "State" &&
                item.name !== "VEHICLE STAGE" &&
                item.name !== "VEHICLE TYPE" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className="form-select"
                      placeholder={item.placeholder}
                      showSearch={item.search}
                      mode={item.multi && "multiple"}
                      onChange={handleState}
                      options={states}
                      autoComplete="off"
                    ></Select>
                  </div>
                )}
              {item.type === "select" && item.name === "Select Parameters" && (
                <div>
                  <Select
                    style={{ paddingLeft: "0" }}
                    className="parameter-select"
                    placeholder={item.placeholder}
                    mode={"multiple"}
                    options={parameters}
                    value={selectedParameters}
                    onChange={handleInputParameter}
                  ></Select>
                </div>
              )}
              {item.type === "select" &&
                item.name === "City" &&
                item.name !== "VEHICLE STAGE" &&
                item.name !== "VEHICLE TYPE" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className="form-select"
                      placeholder={item.placeholder}
                      showSearch={item.search}
                      onChange={handleCity}
                      options={cities}
                      autoComplete="off"
                      value={selectedCity}
                    ></Select>
                  </div>
                )}

              {item.type === "select" &&
                item.name === "SELECT VIN" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className="form-select"
                      placeholder={item.placeholder}
                      showSearch={true}
                      onChange={(e) => handleVINNumber(e)}
                      options={item.option}
                      autoComplete="off"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={updateVinNumber}
                    ></Select>
                  </div>
                )}
              {item.type === "select" &&
                (item.name === "Customer Location" ||
                  item.name === "Location") &&
                item.name !== "VEHICLE STAGE" &&
                item.name !== "VEHICLE TYPE" && (
                  <div>
                    <Select
                      style={{ paddingLeft: "0" }}
                      className="form-select"
                      placeholder={item.placeholder}
                      value={custLocation}
                      showSearch={item.search}
                      onChange={handleLocation}
                      options={locationOption}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      autoComplete="off"
                    ></Select>
                  </div>
                )}
              {item.type === "select" && item.name === "VEHICLE STAGE" && (
                <div>
                  <Select
                    style={{ paddingLeft: "0" }}
                    className="form-select"
                    placeholder={item.placeholder}
                    showSearch={item.search}
                    onChange={(e) => {
                      handleSelect(item.name, e);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={item.option}
                    autoComplete="off"
                    disabled={btnName ? true : false}
                  ></Select>
                </div>
              )}
              {item.type === "select" && item.name === "VEHICLE TYPE" && (
                <div>
                  <Select
                    style={{ paddingLeft: "0" }}
                    className="form-select"
                    placeholder={item.placeholder}
                    showSearch={item.search}
                    onChange={(e) => {
                      handleSelect(item.name, e);
                      setBtnName(false);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={item.option}
                    autoComplete="off"
                  ></Select>
                </div>
              )}
              {item.type === "input-time" && (
                <div>
                  <TimePicker
                    className="timepicker-ant"
                    allowClear={false}
                    onChange={(e) => handleTime(item.name, e)}
                    placeholder={item.placeholder}
                  />
                </div>
              )}
            </div>
          );
        })}
        <button onClick={handleSubmit} className="sbt-btn">
          Submit
        </button>
        {isParameters && (
          <div style={{ textAlign: "left" }}>
            <p className="parameters-report">Vehicle Report:</p>
            <div style={{ display: "flex" }}>
              {selectedParameters.map((parameter, index) => (
                <div key={index}>
                  <p className="parameter">
                    {parameter} {getParameterUnit(parameter, parametersData)}
                  </p>
                  <p className="parameterdata">{parametersData[parameter]}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
