export function getParameterUnit(parameter,parametersData) {
    const parameterUnit = {
      "Distance": "(Km)",
      "Average Current": "(A)",
      "Average Absolute Current": "(A)",
      "Maximum Current":"(A)",
      "Average Speed":"(Kmph)",
      "Average Absolute Speed":"(Kmph)",
      "Maximum Speed":"(Kmph)",
      "Average Motor Power":"(W)",
      "Average Motor Power Absolute":"(W)",
      "Max Motor Power":"(W)",
      "Max Motor Temperature":"(°C)",
      "Max Controller Temperature":"(°C)"
    };
    return parameterUnit[parameter] 
    
  }
  
  
  