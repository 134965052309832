import React, { useState, useEffect } from "react";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Form from "../../Components/Form/Form";
import { useSelector, useDispatch } from "react-redux";
import navData from "../../service/navigation";
import loadash from "lodash";
import Loader from "../../Components/Loader/Loader";
import { setAvailVins } from "../../redux/slices/availableVinsSlice";
import { setCustomers } from "../../redux/slices/customersSlice";
import { useNavigate } from "react-router-dom";
import fetchApiData from "../../service/apiUtils";

const Assign = () => {

// user authentication code
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/assign_customer");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  const [customerList, setCustomerList] = useState(
    useSelector((state) => state.customer.customers)
  );
  // if customer_list is not present 
  useEffect(() => {
    const url = "customers"; // Endpoint to fetch
    const headers = {
      "Content-Type": "application/json",
      ZCFKEY: "027dbc732cbf33c037df6fb82a23aaec",
    };

    if (!customerList) {
      fetchApiData(url)
        .then((data) => {
          let customerOptions = convertCustomerListArray(data.customers);
          setCustomerList(customerOptions);
          dispatch(setCustomers(customerOptions));
          setLoader(false);
        })
        .catch((error) => {
          alert("Some Error!");
          console.error("Error:", error);
        });
    } else {
      setLoader(false);
    }
  }, [customerList]);
  
  function convertCustomerListArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.name,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }
  const dispatch = useDispatch();
  const [availableVins, setAvailableVins] = useState(
    useSelector((state) => state.availVins.availableVins)
  );
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const endpoint = "vehiclesList?actionName=assignVehicles"; // Endpoint to fetch
    fetchApiData(endpoint)
      .then((data) => {
        let available = convertArray(data.vins); // Assuming convertArray function is defined
        dispatch(setAvailVins(available));
        setAvailableVins(available);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.vin,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const option = useSelector((state) => state.vin.vin);
  function convertArrayToObject(arr) {
    return arr.map((item) => {
      return { value: item, label: item };
    });
  }

  let vins = convertArrayToObject(option);

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[3].class = true;

  const formData = [
    {
      name: "Customer Name",
      type: "select",
      placeholder: "Customer Name",
      search: true,
      option: customerList,
    },
    {
      name: "Location",
      type: "select",
      placeholder: "Location",
      search: false,
    },
    { name: "Start Date", type: "datepicker", placeholder: "Start Date" },
    {
      name: "Start Time",
      type: "input-time",
      placeholder: "Start Time",
      mandt: false,
    },
    {
      name: "End Date",
      type: "datepicker",
      placeholder: "End Date",
      mandt: false,
    },
    {
      name: "End Time",
      type: "input-time",
      placeholder: "End Time",
      mandt: false,
    },
    {
      name: "Select VIN",
      type: "select",
      placeholder: "Select VIN",
      search: true,
      multi: true,
      option: availableVins,
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form heading={"Assign Vehicles"} data={formData} formName="assign" />
      <Footer />
    </>
  );
};

export default Assign;
