import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mapData : [] 
}

const mapDataSlice = createSlice({
    name: "mapData",
    initialState,
    reducers: {
        setMapData : (state, action) => {
            state.mapData = action.payload;
        }
    }
})

export const {setMapData} = mapDataSlice.actions;
export default mapDataSlice.reducer; 