import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    availableVins: null
}

const vins = createSlice({
    name: "available_vins",
    initialState,
    reducers: {
        setAvailVins : (state, action) => {
            state.availableVins = action.payload;
        },
    }
})

export default vins.reducer;
export const {setAvailVins} = vins.actions; 