import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import loadash from "lodash";
import {
  setVehicleType,
  setVehicleCategory,
} from "../../redux/slices/vinGenratorSlice";
import Form from "../../Components/Form/Form";
import navData from "../../service/navigation";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import products from "../../assets/products";
import { setAvailVins } from "../../redux/slices/availableVinsSlice";
import Loader from "../../Components/Loader/Loader";
import fetchApiData from "../../service/apiUtils";

const VinGenerator = () => {
  const [isVinGen, setIsVinGen] = useState(true);
  const [loader, setLoader]  = useState(false);
  const [availableVins, setAvailableVins] = useState(
    useSelector((state) => state.availVins.availableVins));

  useEffect(() => {
    setLoader(true);
    const endpoint = "/availVinsByCategory"; // Endpoint to fetch
    fetchApiData(endpoint)
      .then((data) => {
        let available = convertArray(data.vins);
        // dispatch(setAvailVins(available));
        setAvailableVins(available);
        
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      setLoader(false);
  }, []);

  function convertArray(array) {
    const convertedArray = array.map((item) => {
      return {
        label: item.vin,
        value: item.ROWID,
      };
    });
    return convertedArray;
  }

  const dispatch = useDispatch();
  const {
    selectedVehicleTypeOptions,
    selectedVehicleCategoryOptions,
    generatedVIN,
  } = useSelector((state) => state.vin);

  let fields = loadash.cloneDeep(navData);
  fields[4].enable = true;
  fields[4].class = true;
  fields[4].subheading[5].class = true;

  const handleVehicleTypeChange = (e) => {
    dispatch(setVehicleType(e.target.value));
  };

  const handleVehicleCategoryChange = (e) => {
    dispatch(setVehicleCategory(e.target.value));
  };

  const formData = [
    {
      name: "VEHICLE TYPE",
      type: "select",
      placeholder: "Select Vehicle",
      option: products,
      value: selectedVehicleTypeOptions,
      onChange: handleVehicleTypeChange,
    },
    {
      name: "VEHICLE STAGE",
      type: "select",
      placeholder: "Select Stage",
      option: [
        { value: "Production", label: "Production" },
        { value: "R&D", label: "R&D" },
      ],
      value: selectedVehicleCategoryOptions,
      onChange: handleVehicleCategoryChange,
    },
    {
      name: "GENERATED VIN",
      type: "input",
      attr: "text",
      value: generatedVIN || "",
      readOnly: true,
      disabled: true,
      placeholder: "Generated VIN",
    },
  ];
  const updateVin = [
    {
      name: "SELECT VIN",
      type: "select",
      placeholder: "Select VIN",
      option: availableVins,
    },
    {
      name:"CURRENT STAGE",
      type:"input",
      attr:"text",
      placeholder:"Current Stage",
      readOnly:true,
      disabled:true
    },
    {
      name: "UPDATE STAGE",
      type: "select",
      placeholder: "Select Stage",
      option: [
        { value: "R&D", label: "R&D" },
        { value: "Production", label: "Production" },
        { value: "Inventory", label: "Inventory" },
        { value: "Sold", label: "Sold" },
        { value: "Rented", label: "Rented" },
      ],
    }
  ];

  const handleToggle = (e) => {
    setIsVinGen(e)
  } 

  return (
    <>
    {loader && <Loader />}
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form heading="VIN GENERATOR" data={isVinGen ? formData: updateVin} formName={isVinGen ? "vinGenerator": "updateVin"} isVin={handleToggle} vinGEn={isVinGen} />
      <Footer />
      <div className="vin-main">
        <h2 className="vin-heading">VIN Generator</h2>
        <div className="vin-body"></div>
      </div>
    </>
  );
};

export default VinGenerator;
