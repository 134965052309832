import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vin : null,
    date: null,
}

const vinDetail = createSlice({
    name: "vinDetails",
    initialState,
    reducers: {
        setVinNo : (state, action) => {
            state.vin = action.payload;
        },
        setDate : (state, action) => {
            state.date = action.payload;
        }
    }
})

export default vinDetail.reducer;
export const {setVinNo, setDate} = vinDetail.actions; 