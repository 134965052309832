// productsData js

const products = [
  {
    value: "MOPTro SNAIL",
    label: "MOPTro SNAIL",
  },
  {
    value: "MOPTro LITE ",
    label: "MOPTro LITE ",
  },
  {
    value: "MOPTro LITE + ",
    label: "MOPTro LITE + ",
  },
  // {
  //   value: "NXP400 ",
  //   label: "NXP400 ",
  // },
  // {
  //   value: "NXP500 ",
  //   label: "NXP500 ",
  // },
  // {
  //   value: "NXP750 ",
  //   label: "NXP750 ",
  // },
];

export default products;
