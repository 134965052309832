import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../Components/Footer/Footer";

import "./loginbody.css";
import logo from "../../assets/INDEX_LOGO.png";
import v1 from "../../assets/wasp.png";
import v2 from "../../assets/moptroB.png";
import v3 from "../../assets/snail.png";
import v4 from "../../assets/snailLite.png";
import w2h from "../../assets/w2hLogo.png";
const Body = function () {
  const [loader, setLoader] = useState(false);
  const [authenticated, setauthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false)
  );
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const navigate = useNavigate();
  let loggedIn = false;
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    var body = { loginId: username, password: password };
    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_DEV
          : process.env.REACT_APP_API_KEY_PROD,
    };
    let url;
    url =
      (process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV
        : process.env.REACT_APP_API_BASE_URL_PROD) + "login";

    let response = await axios
      .post(url, body, {
        headers: headers,
      })
      .then(function (response) {
        if (response.data.status === 200) {
          loggedIn = true;
          setauthenticated(true);
          localStorage.setItem("authenticated", true);
          navigate("/app/livedashboard");
          toast(response.data.message);
        } else {
          alert(response.data.message);
          setLoader(false);
        }
      })
      .catch(function (error) {
        alert("Wrong Email or password!");
        setLoader(false);
      });
  };

  return (
    <div className="login-main">
      <div className="login-heading">
        <img width={70} src={w2h} alt="w2h_logo" />
        W2H Connect Platform
      </div>
      {loader && (
        <div style={{ display: "flex" }} id="main-alert-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="data">
        <div className="mob-login-logo">
          <img
            width={60}
            src={logo}
            alt="index-logo"
            style={{ paddingTop: "5px" }}
          />
          <h1 className="mob-login-title">INDEX</h1>
          <h3
            className="text-mob"
            style={{ marginTop: "0", marginBottom: "0" }}
          >
            Dashboard to monitor vehicle performance
          </h3>
          <h3 className="text-mob" style={{ marginTop: "0" }}>
            using processed data graphs.
          </h3>
        </div>
        <form className="sign-in" onSubmit={handleSubmit}>
          <h2 className="login-signin">Sign in</h2>
          <input
            className="login-input"
            type="text"
            name="username"
            value={username}
            placeholder="E-mail"
            onChange={(e) => setusername(e.target.value)}
            autoComplete="off"
            required={true}
          />
          <input
            className="login-input"
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setpassword(e.target.value)}
            required={true}
          />
          <div className="forget-password">Forgot Password?</div>
          <button id="login-button" type="submit">
            Login
          </button>
        </form>
        <div className="login-logo">
          <img width={70} src={logo} alt="" />
          <h1 className="login-title" style={{ fontSize: "1.5rem" }}>
            Index
          </h1>
          <p>
            Dashboard to monitor vehicle performance using processed data
            graphs.
          </p>
        </div>
      </div>

      <div className="moptros">
        <div className="vehicle">
          <img src={v1} alt="" />
          <h3>WASP®</h3>
        </div>
        <div className="vehicle">
          <img src={v3} alt="" />
          <h3>SNAIL®</h3>
        </div>
        <div className="vehicle">
          <img src={v4} alt="" />
          <h3>LITE®</h3>
        </div>
        <div className="vehicle">
          <img src={v2} alt="" />
          <h3>MoptroB®</h3>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Body;
