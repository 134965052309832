import React, { useEffect, useState } from "react";
import Head from "../../../Components/Head/Head";
import Footer from "../../../Components/Footer/Footer";
import SideBody from "../../../Components/SideNavBar/SideNavBar";
import Form from "../../../Components/Form/Form";
import navData from "../../../service/navigation";
import loadash from "lodash";
import { useNavigate } from "react-router-dom";
// import Loader

const Register = () => {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);

  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/customer/register");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);
  const [body, setBody] = useState(null)
  let feilds = loadash.cloneDeep(navData); 
  feilds[4].enable = true;
  feilds[4].class = true;
  feilds[4].subheading[0].class = true;

  const vertical = [
    { value: "Quick Commerce", label: "Quick Commerce" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Automobile", label: "Automobile" },
    { value: "Manufacturing", label: "Manufacturing" },
  ];

  const formData = [
    { name: "Name", type: "input", attr: "text", placeholder: "Name" },
    { name: "Website", type: "input", attr: "text", placeholder: "Website" },
    {
      name: "Vertical",
      type: "select",
      placeholder: "Select Vertical",
      search: false,
      option: vertical,
    },
  ];

  const handleBodydata = (e) => {
    setBody(e);
  }


  return (
    <>
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {feilds.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>
      <Form
        heading={"Register Customer"}
        data={formData}
        formName={"customerName"}
        bodydata = {handleBodydata}
      />
      <Footer />
    </>
  );
};

export default Register;
