import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customers : null
}

const customers = createSlice({
    name: "customers",
    initialState,
    reducers: {
        setCustomers : (state, action) => {
            state.customers = action.payload;
        },
    }
})

export default customers.reducer;
export const {setCustomers} = customers.actions; 