const parametersData=[
    {
        "value":"Ignition Cycles",
        "label":"Ignition Cycles"
    },
    {
        "value":"ChargeCycles",
        "label":"ChargeCycles"
    },
    {
        "value":"Distance",
        "label":"Distance"
    },
    {
        "value":"Start Stop Cycles",
        "label":"Start Stop Cycles"
    },
    {
        "value":"OverLoadCount",
        "label":"OverLoadCount"
    },
    {
        "value":"BrakesCount",
        "label":"BrakesCount"
    },
    {
        "value":"Average Current",
        "label":"Average Current"
    },
    {
        "value":"Average Absolute Current",
        "label":"Average Absolute Current"
    },
    {
        "value":"Maximum Current",
        "label":"Maximum Current"
    },
    {
        "value":"Average Speed",
        "label":"Average Speed"
    },
    {
        "value":"Average Absolute Speed",
        "label":"Average Absolute Speed"
    },
    {
        "value":"Maximum Speed",
        "label":"Maximum Speed"
    },
    {
        "value":"Average Motor Power",
        "label":"Average Motor Power"
    },
    {
        "value":"Average Motor Power Absolute",
        "label":"Average Motor Power Absolute"
    },
    {
        "value":"Max Motor Power",
        "label":"Max Motor Power"
    },
    {
        "value":"Max Motor Temperature",
        "label":"Max Motor Temperature"
    },
    {
        "value":"Max Controller Temperature",
        "label":"Max Controller Temperature"
    },
    {
        "value":"Emergency Count",
        "label":"Emergency Count"
    },
    {
        "value":"PushButton Count",
        "label":"PushButton Count"
    },
    {
        "value":"Reverse Button Count",
        "label":"Reverse Button Count"
    }
]
module.exports = parametersData