import React, { useState } from "react";
import {DatePicker } from "antd";
import moment from "moment";
import "./timerender.css";

const TimeRangePicker = ({ onSelect, date, availableDates }) => {
  const [selectedDate, setSelectedDate] = useState(date);
  const today = new Date();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onSelect(date);
  };

  const disabledDate = current => {
    // Disable dates after today and not in the available dates array
    return (
      current && 
      (current.isAfter())
    );
  };

  return (
    <div style={{ width: "100%" }} className="time_pick">
      <DatePicker
        onChange={handleDateChange}
        defaultValue={today}
        value={selectedDate}
        className="custom-range-picker"
        disabledDate={disabledDate}
        placeholder={"SELECT DATE"}
        format="DD-MM-YYYY"
        allowClear={false}
      />
    </div>
  );
};

export default TimeRangePicker;
