import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import LogBody from "./LogBody";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import navData from "../../service/navigation";
import loadash from "lodash";

const Logs = () => {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");
  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/logs/");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);
  let fields = loadash.cloneDeep(navData);
  fields[3].class = true;

  return (
    <>
      <Head />
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
            />
          ))}
        </ul>
      </div>
      <LogBody />
      <Footer />
    </>
  );
};

export default Logs;
