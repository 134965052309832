import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vin : []
}

const vinSlice = createSlice({
    name: "vin",
    initialState,
    reducers: {
        setVin : (state, action) => {
            state.vin = action.payload;
        }
    }
})

export const { setVin} = vinSlice.actions;
export default vinSlice.reducer;
